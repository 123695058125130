<template>
  <div class="pbf">
     
     <BrandTabProducts
      :inurl="'/brands-lamp/' + props.id + '/products'"
      :brand-id="props.id"
      :category="'lamp'"
      :intags="{}"
      />

  </div>
</template>

<script setup lang="ts">

interface Props {
  id: number
}

const props = defineProps<Props>()

</script>